import { useQuery } from "@tanstack/react-query";
import axios from "../../libs/axios/axios";
import { getDefaultStore } from "jotai";
import { authenticatedUserAtom } from "../../hooks/authenticatedUserAtom";
import { isAuthenticatedAtom } from "../../hooks/isAuthenticatedAtom";
const store = getDefaultStore();

export default function useMe() {
  return useQuery({
    queryKey: ["me"],
    queryFn: async () => {
      return axios
        .get("/me")
        .then((res) => {
          store.set(authenticatedUserAtom, res.data);
          return res.data;
        })
        .catch((err) => {
          store.set(isAuthenticatedAtom, false);
          store.set(authenticatedUserAtom, {});
          return {};
        });
    },
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });
}
